@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/* Custom css */
html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
